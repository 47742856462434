import {User} from '@innobile/authmodule';

export class LaundryUser extends User {
  private selectedLaundry: number | null;
  private readonly laundries: { id: number, name: string }[];

  constructor(raw: any) {
    super(raw);
    this.laundries = raw.laundries || [];
    const localLaundry = localStorage.getItem('selectedLaundry');
    try {
      this.selectedLaundry = localLaundry ? JSON.parse(localLaundry) : null;
    } catch (ex) {
      localStorage.removeItem('selectedLaundry');
      this.selectedLaundry = null;
    }
    if (this.laundries.length && this.selectedLaundry === null) {
      this.selectedLaundry = this.laundries[0].id;
    }
  }

  get Laundries(): { id: number, name: string }[] {
    return this.laundries;
  }

  get SelectedLaundry(): { id: number, name: string } | null {
    return this.laundries.find(l => l.id === this.selectedLaundry) || null;
  }

  set SelectedLaundry(laundry: { id: number, name: string } | null) {
    if (laundry) {
      this.selectedLaundry = laundry.id;
      localStorage.setItem('selectedLaundry', '' + this.selectedLaundry);
    } else {
      this.selectedLaundry = null;
      localStorage.removeItem('selectedLaundry');
    }
    window.location.reload();
  }

  destroy(): void {
    localStorage.removeItem('selectedLaundry');
    super.destroy();
  }
}
