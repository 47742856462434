<div class="login-box">
  <img src="assets/img/logo.svg" alt="Logo"/>

  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="input" [class.has-error]="loginForm.get('email')!.invalid">
      <i class="material-icons-outlined">account_circle</i>
      <input type="email" placeholder="E-mail" formControlName="email" id="email"/>
    </div>
    <div class="input" [class.has-error]="loginForm.get('password')!.invalid">
      <i class="material-icons-outlined">lock_open</i>
      <input type="password" placeholder="Jelszó" formControlName="password" id="password"/>
    </div>
    <div class="info orange" *ngIf="loginForm.hasError('login')">
      <i class="material-icons">error</i>
      <span>{{ loginForm.getError('login') === 'invalid' ? 'Érvénytelen e-mail cím/jelszó' : 'Ismeretlen hiba'}}</span>
    </div>
    <div class="buttons">
      <button class="blue" type="submit">
        <i class="material-icons">arrow_forward</i>
        Bejelentkezés
      </button>
    </div>
  </form>
</div>
