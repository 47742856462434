import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthError, AuthService} from '@innobile/authmodule';
import {LaundryUser} from '../laundry-user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    password: new UntypedFormControl(null, [Validators.required])
  });
  private redirect?: string | null;

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('redirect')) {
        this.redirect = params.redirect;
      }
    });
  }

  login(): void {
    if (this.loginForm.valid) {
      this.auth.logIn({
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value
      }).subscribe((user: LaundryUser) => {
        const moduleName = this.redirect?.split('/').find(value => value);
        const hasModule = user && user.modules && user.modules.find(module => moduleName && module.name.toLowerCase() === moduleName);
        const redirect = this.redirect && hasModule ? this.redirect : '';
        this.redirect = null;
        this.router.navigateByUrl(redirect);
      }, error => {
        console.error(error);
        if (error instanceof AuthError) {
          this.loginForm.setErrors({login: 'invalid'});
        } else {
          this.loginForm.setErrors({login: 'unknown'});
        }
      });
    }
  }
}
