import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LaundryUser} from './laundry-user';
import {User, UserService} from '@innobile/authmodule';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable()
export class LaundriesInterceptor implements HttpInterceptor {
  private user?: LaundryUser;

  constructor(private userService: UserService) {
    this.userService.userSubject.subscribe((user: User) => {
      this.user = user as LaundryUser;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.apiPrefix) && this.user) {
      if (this.user.SelectedLaundry !== null) {
        req = req.clone({
          headers: req.headers.append('x-pozi-laundry', this.user.SelectedLaundry.id.toString())
        });
      }
    }
    return next.handle(req);
  }
}
