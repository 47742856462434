import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CustomAuthModule} from '@innobile/authmodule';
import {environment} from '../environments/environment';
import {LaundryUser} from './laundry-user';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LaundriesInterceptor} from './laundries.interceptor';
import {SharedVariablesService} from './shared/shared-variables.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    CustomAuthModule.forRoot({
      config: {
        apiPrefix: environment.apiPrefix,
        userModel: LaundryUser,
        loginEndpoint: 'v1/authenticate',
        whitelistedApiCalls: ['v1/authenticate'],
        userInfoEndpoint: 'v1/userinfo'
      }
    }),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: LaundriesInterceptor,
    multi: true
  },
    SharedVariablesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
